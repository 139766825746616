<template>
  <div>
    <v-card flat id="assigned-parties">
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" class="my-0 py-0">
            <v-list :key="updateKey" dense subheader :class="$vuetify.breakpoint.mobile ? 'px-0 mx-0' : 'px-3'">
              <!-- <v-row align="center" >
                <v-col cols="12" sm="6" class="mb-0 pb-0">
                 
                </v-col>
                <v-col cols="12" sm="6" class="mb-0 pb-0">
                 
                </v-col>
              </v-row> -->

              <v-subheader class="mt-0 pt-0 primaryText--text" style="font-size: 16px">
                <v-icon left color="grey">groups</v-icon> Assigned Parties
                <v-btn v-if="availableRoles.length > 0" @click="addParty('new')" icon color="primary"
                  id="add-assigned-parties"><v-icon>add_circle_outline</v-icon></v-btn> <v-spacer></v-spacer> <v-checkbox
                  v-model="item.letterOfCredit" label="Letter of Credit Required"
                  id="checkbox-credit-letter"></v-checkbox>
              </v-subheader>
              <v-list-item class="dense-list-item" v-if="availableRoles.length == 7">
                <v-list-item-content class="text-center">
                  <span>No parties listed.</span>
                </v-list-item-content>
              </v-list-item>
              <!-- <v-list-item class="dense-list-item" v-if="item.letterOfCredit">
                <v-list-item-action>
                  <v-col cols="12" class="text-center ml-2 pr-4">
                    <v-icon>account_balance</v-icon> <br />
                    <span style="font-size: 10px; font-weight: bold">BANK</span>
                  </v-col>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    <span v-if="item.bank">{{ item.bank.name }}</span>
                    <span v-else>-</span>
                  </v-list-item-title>
                  <v-list-item-subtitle
                    style="font-size: 14px"
                    v-if="item.bankAddressId || item.bankAddressDescription"
                    class="text-wrap"
                  >
                    <v-icon class="mr-1" small>location_on</v-icon>
                    <span v-if="item.bankAddressDescription">{{
                      item.bankAddressDescription
                    }}</span>
                    <span v-else>{{
                      concatenateAddress(item.bankAddress)
                    }}</span>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-row>
                    <v-tooltip top v-if="!item.bank">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-bind="attrs"
                          v-on="on"
                          @click="addParty('bank')"
                          ><v-icon>add</v-icon></v-btn
                        >
                      </template>
                      <span>Attach Bank</span>
                    </v-tooltip>

                    <v-tooltip top v-else>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-bind="attrs"
                          v-on="on"
                          @click="editParty('bank', item.bank)"
                          ><v-icon>edit</v-icon></v-btn
                        >
                      </template>
                      <span>Edit Bank</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-bind="attrs"
                          v-on="on"
                          color="red"
                          @click="removeParty('bank')"
                          ><v-icon>delete</v-icon></v-btn
                        >
                      </template>
                      <span>Remove Party</span>
                    </v-tooltip>

                    <v-tooltip top v-if="item.bank && !item.bankAddressId">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-bind="attrs"
                          v-on="on"
                          color="orange"
                          small
                          @click="addAddress('bank')"
                          ><v-icon small>add_location</v-icon></v-btn
                        >
                      </template>
                      <span>Add Address Details</span>
                    </v-tooltip>
                  </v-row>
                </v-list-item-action>
              </v-list-item> -->

              <!-- Bank -->
              <v-card outlined v-if="item.letterOfCredit">
                <v-row align="center" class="mt-1">
                  <v-col cols="12" sm="2" class="text-center">
                    <v-icon class="mt-0 pt-0">account_balance</v-icon>
                    <br />
                    <span style="font-size: 10px; font-weight: bold">BANK</span>
                  </v-col>
                  <v-col cols="12" sm="10" class="my-0 py-0">
                    <v-card-title :style="{
                      'font-size': '14px',
                      'padding-bottom': '0px'
                    }" class="my-0 pt-0"><span v-if="item.bank">{{ item.bank.name }}</span>
                      <span v-else>-</span>

                      <v-spacer></v-spacer>
                      <div v-if="!$vuetify.breakpoint.mobile">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" small @click="editParty('bank', item.bank)"
                              id="edit-party"><v-icon small>edit</v-icon></v-btn>
                          </template>
                          <span>Edit Bank</span>
                        </v-tooltip>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" color="red" small @click="removeParty('bank')"
                              id="remove-party"><v-icon small>delete</v-icon></v-btn>
                          </template>
                          <span>Remove Party</span>
                        </v-tooltip>

                        <v-tooltip top v-if="item.bank && !item.bankAddressId">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" color="orange" small @click="addAddress('bank')"><v-icon
                                small>add_location</v-icon></v-btn>
                          </template>
                          <span>Add Address Details</span>
                        </v-tooltip>
                      </div>
                    </v-card-title>

                    <v-card-text class="my-0 mt-0 pt-0">
                      <p style="font-size: 12px; color: grey" v-if="item.bankAddressId ||
                        item.bankAddressDescription
                        " class="text-wrap my-0 py-0">
                        <v-icon class="mr-1" small>location_on</v-icon>
                        <span v-if="item.bankAddressDescription">{{
                          item.bankAddressDescription
                        }}</span>
                        <span v-else>{{
                          concatenateAddress(item.bankAddress)
                        }}</span>
                      </p>
                    </v-card-text>
                  </v-col>
                </v-row>
                <v-card-actions v-if="$vuetify.breakpoint.mobile">
                  <v-row justify="center">
                    <v-col cols="6">
                      <v-btn text small @click="editParty('bank', item.bank)"><v-icon small left>edit</v-icon>
                        Edit</v-btn>
                    </v-col>
                    <v-col cols="6">
                      <v-btn text color="red" small @click="removeParty('bank')"><v-icon small left>delete</v-icon>
                        Remove</v-btn>
                    </v-col>
                    <v-col cols="12">
                      <v-btn text v-if="item.bank && !item.bankAddressId" color="orange" small
                        @click="addAddress('bank')"><v-icon small left>add_location</v-icon> Add
                        Address</v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>

              <!-- Shipper -->
              <v-card flat v-if="item.shipper">

                <v-row align="center" class="mt-1">
                  <v-col cols="12" sm="2" class="text-center my-0 py-0">
                    <v-icon class="mt-0 pt-0">directions_boat_filled</v-icon>
                    <br />
                    <span style="font-size: 10px; font-weight: bold">SHIPPER</span>
                  </v-col>
                  <v-col cols="12" sm="10" class="my-0 py-0">
                    <v-card-title :style="{
                      'font-size': '14px',
                      'padding-bottom': !item.onBehalfShipper ? '0px' : '6px',
                    }" class="my-0 pt-0">{{ item.shipper.name }}
                      <v-chip class="ml-2" label x-small v-if="item.shipper && !item.onBehalfShipper"
                        @click="addParty('onBehalfShipper')"><v-icon small left>add</v-icon> {{ selectedOBOShipper }} </v-chip>
                      <v-spacer></v-spacer>
                      <div v-if="!$vuetify.breakpoint.mobile">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" small @click="editParty('shipper', item.shipper)"
                              id="edit-party"><v-icon small>edit</v-icon></v-btn>
                          </template>
                          <span>Edit Shipper</span>
                        </v-tooltip>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" color="red" small @click="removeParty('shipper')"
                              id="remove-party"><v-icon small>delete</v-icon></v-btn>
                          </template>
                          <span>Remove Party</span>
                        </v-tooltip>

                        <v-tooltip top v-if="item.shipper && !item.shipperAddressId">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" color="orange" small
                              @click="addAddress('shipper')"><v-icon small>add_location</v-icon></v-btn>
                          </template>
                          <span>Add Address Details</span>
                        </v-tooltip>
                      </div>
                    </v-card-title>
                    <v-card-subtitle style="font-size: 14px" class="mb-0 pb-0"
                      v-if="item.shipper && item.onBehalfShipper">
                      On Behalf of
                      <a @click="
                        editParty('onBehalfShipper', item.onBehalfShipper)
                        ">{{ item.onBehalfShipper.name }}</a></v-card-subtitle>
                    <v-card-text class="my-0 mt-0 pt-0" v-if="item.shipper">
                      <p style="font-size: 12px; color: grey" v-if="item.shipperAddressId ||
                        item.shipperAddressDescription
                        " class="text-wrap my-0 py-0">
                        <v-icon class="mr-1" small>location_on</v-icon>
                        <span v-if="item.shipperAddressDescription">{{
                          item.shipperAddressDescription
                        }}</span>
                        <span v-else>{{
                          concatenateAddress(item.shipperAddress)
                        }}</span>
                      </p>
                      <p v-if="item.onBehalfShipper && item.onBehalfShipperAddress" style="font-size: 12px; color: grey"
                        class="text-wrap my-0 py-0">
                        <v-icon class="mr-1" small>location_on</v-icon>
                        <span>{{
                          concatenateAddress(item.onBehalfShipperAddress)
                        }}</span>
                      </p>
                    </v-card-text>
                    <!-- <v-card-text class="my-0 mt-0 pt-0">
                   
                    </v-card-text> -->
                  </v-col>
                </v-row>
                <v-card-actions v-if="$vuetify.breakpoint.mobile">
                  <v-row justify="center">
                    <v-col cols="6">
                      <v-btn text small @click="editParty('shipper', item.shipper)"><v-icon small left>edit</v-icon>
                        Edit</v-btn>
                    </v-col>
                    <v-col cols="6">
                      <v-btn text color="red" small @click="removeParty('shipper')"><v-icon small left>delete</v-icon>
                        Remove</v-btn>
                    </v-col>
                    <v-col cols="12">
                      <v-btn text v-if="item.shipper && !item.shipperAddressId" color="orange" small
                        @click="addAddress('shipper')"><v-icon small left>add_location</v-icon> Add
                        Address</v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
              <v-divider v-if="item.shipper" class="my-3" style="color: grey"></v-divider>

              <!-- forwarder -->
              <v-card flat v-if="item.forwarder">
                <v-row align="center" class="mt-1">
                  <v-col cols="12" sm="2" class="text-center my-0 py-0">
                    <v-icon>fast_forward</v-icon> <br />
                    <span style="font-size: 10px; font-weight: bold">FORWARDER</span>
                  </v-col>
                  <v-col cols="12" sm="10" class="my-0 py-0">
                    <v-card-title :style="{
                      'font-size': '14px',
                      'padding-bottom': !item.onBehalfForwarder
                        ? '0px'
                        : '5px',
                    }" class="my-0 pt-0">{{ item.forwarder.name }}
                      <v-chip class="ml-2" label x-small v-if="item.forwarder && !item.onBehalfForwarder"
                        @click="addParty('onBehalfForwarder')"><v-icon small left>add</v-icon> {{ selectedOBOBuyer }} </v-chip>
                      <v-spacer></v-spacer>
                      <div v-if="!$vuetify.breakpoint.mobile">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" small
                              @click="editParty('forwarder', item.forwarder)"><v-icon small>edit</v-icon></v-btn>
                          </template>
                          <span>Edit Forwarder</span>
                        </v-tooltip>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" color="red" small
                              @click="removeParty('forwarder')"><v-icon small>delete</v-icon></v-btn>
                          </template>
                          <span>Remove Party</span>
                        </v-tooltip>

                        <v-tooltip top v-if="item.forwarder && !item.forwarderAddressId">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" color="orange" small
                              @click="addAddress('forwarder')"><v-icon small>add_location</v-icon></v-btn>
                          </template>
                          <span>Add Address Details</span>
                        </v-tooltip>
                      </div>
                    </v-card-title>
                    <v-card-subtitle style="font-size: 14px" class="mb-0 pb-0"
                      v-if="item.forwarder && item.onBehalfForwarder">
                      On Behalf of
                      <a @click="
                        editParty('onBehalfForwarder', item.onBehalfForwarder)
                        ">{{ item.onBehalfForwarder.name }}</a></v-card-subtitle>
                    <v-card-text class="my-0 mt-0 pt-0" v-if="item.forwarder">
                      <p style="font-size: 12px; color: grey" v-if="item.forwarderAddressId ||
                        item.forwarderAddressDescription
                        " class="text-wrap my-0 py-0">
                        <v-icon class="mr-1" small>location_on</v-icon>
                        <span v-if="item.forwarderAddressDescription">{{
                          item.forwarderAddressDescription
                        }}</span>
                        <span v-else>{{
                          concatenateAddress(item.forwarderAddress)
                        }}</span>
                      </p>
                      <p v-if="item.onBehalfForwarder && item.onBehalfForwarderAddress"
                        style="font-size: 12px; color: grey" class="text-wrap my-0 py-0">
                        <v-icon class="mr-1" small>location_on</v-icon>
                        <span>{{
                          concatenateAddress(item.onBehalfForwarderAddress)
                        }}</span>
                      </p>
                    </v-card-text>
                    <!-- <v-card-text class="my-0 mt-0 pt-0" v-if="item.onBehalfForwarder && item.onBehalfForwarderAddress">
                    
                    </v-card-text> -->
                  </v-col>
                </v-row>
                <v-card-actions v-if="$vuetify.breakpoint.mobile">
                  <v-row justify="center">
                    <v-col cols="6">
                      <v-btn text small @click="editParty('forwarder', item.forwarder)"><v-icon small left>edit</v-icon>
                        Edit</v-btn>
                    </v-col>
                    <v-col cols="6">
                      <v-btn text color="red" small @click="removeParty('forwarder')"><v-icon small left>delete</v-icon>
                        Remove</v-btn>
                    </v-col>
                    <v-col cols="12">
                      <v-btn text v-if="item.forwarder && !item.forwarderAddressId" color="orange" small
                        @click="addAddress('forwarder')"><v-icon small left>add_location</v-icon> Add
                        Address</v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
              <v-divider v-if="item.forwarder" class="my-3" style="color: grey"></v-divider>


              <!-- consignee -->
              <v-card flat v-if="item.consignee">
                <v-row align="center" class="mt-1">
                  <v-col cols="12" sm="2" class="text-center my-0 py-0">
                    <v-icon>call_received</v-icon> <br />
                    <span style="font-size: 10px; font-weight: bold">CONSIGNEE</span>
                  </v-col>
                  <v-col cols="12" sm="10" class="my-0 py-0">
                    <v-card-title :style="{
                      'font-size': '14px',
                      'padding-bottom': !item.onBehalfConsignee && !(item.letterOfCredit && item.bank)
                        ? '0px'
                        : '5px',
                    }" class="my-0 pt-0">{{ item.consignee.name }}
                      <v-chip class="ml-2" label x-small v-if="item.consignee && !item.onBehalfConsignee"
                        @click="addParty('onBehalfConsignee')"><v-icon small left>add</v-icon> {{ selectedOBOConsignee }} </v-chip>
                      <v-spacer></v-spacer>
                      <div v-if="!$vuetify.breakpoint.mobile">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" small
                              @click="editParty('consignee', item.consignee)"><v-icon small>edit</v-icon></v-btn>
                          </template>
                          <span>Edit Consignee</span>
                        </v-tooltip>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" color="red" small
                              @click="removeParty('consignee')"><v-icon small>delete</v-icon></v-btn>
                          </template>
                          <span>Remove Party</span>
                        </v-tooltip>

                        <v-tooltip top v-if="item.consignee && !item.consigneeAddressId">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" color="orange" small
                              @click="addAddress('consignee')"><v-icon small>add_location</v-icon></v-btn>
                          </template>
                          <span>Add Address Details</span>
                        </v-tooltip>
                      </div>
                    </v-card-title>
                    <v-card-subtitle style="font-size: 14px" class="mb-0 pb-0"
                      v-if="item.consignee && item.onBehalfConsignee">
                      On Behalf of
                      <a @click="
                        editParty('onBehalfConsignee', item.onBehalfConsignee)
                        ">{{ item.onBehalfConsignee.name }}</a></v-card-subtitle>
                    <v-card-subtitle style="font-size: 14px" class="mb-0 pb-0" v-if="item.letterOfCredit && item.bank">
                      To the Order of
                      <a @click="
                        editParty('bank', item.bank)
                        ">{{ item.bank.name }}</a></v-card-subtitle>
                    <v-card-text class="my-0 mt-0 pt-0" v-if="item.consignee">
                      <p style="font-size: 12px; color: grey" v-if="item.consigneeAddressId ||
                        item.consigneeAddressDescription
                        " class="text-wrap my-0 py-0">
                        <v-icon class="mr-1" small>location_on</v-icon>
                        <span v-if="item.consigneeAddressDescription">{{
                          item.consigneeAddressDescription
                        }}</span>
                        <span v-else>{{
                          concatenateAddress(item.consigneeAddress)
                        }}</span>
                      </p>
                      <p v-if="item.onBehalfConsignee && item.onBehalfConsigneeAddress"
                        style="font-size: 12px; color: grey" class="text-wrap my-0 py-0">
                        <v-icon class="mr-1" small>location_on</v-icon>
                        <span>{{
                          concatenateAddress(item.onBehalfConsigneeAddress)
                        }}</span>
                      </p>
                    </v-card-text>
                  </v-col>
                </v-row>
                <v-card-actions v-if="$vuetify.breakpoint.mobile">
                  <v-row justify="center">
                    <v-col cols="6">
                      <v-btn text small @click="editParty('consignee', item.consignee)"><v-icon small left>edit</v-icon>
                        Edit</v-btn>
                    </v-col>
                    <v-col cols="6">
                      <v-btn text color="red" small @click="removeParty('consignee')"><v-icon small left>delete</v-icon>
                        Remove</v-btn>
                    </v-col>
                    <v-col cols="12">
                      <v-btn text v-if="item.consignee && !item.consigneeAddressId" color="orange" small
                        @click="addAddress('consignee')"><v-icon small left>add_location</v-icon> Add
                        Address</v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
              <v-divider v-if="item.consignee" class="my-3" style="color: grey"></v-divider>


              <!-- Buyer -->
              <v-card flat v-if="item.buyer">
                <v-row align="center" class="mt-1">
                  <v-col cols="12" sm="2" class="text-center my-0 py-0">
                    <v-icon>shopping_cart</v-icon> <br />
                    <span style="font-size: 10px; font-weight: bold">BUYER</span>
                  </v-col>
                  <v-col cols="12" sm="10" class="my-0 py-0">
                    <v-card-title :style="{
                      'font-size': '14px',
                      'padding-bottom': !item.onBehalfBuyer
                        ? '0px'
                        : '5px',
                    }" class="my-0 pt-0">{{ item.buyer.name }}
                      <v-chip class="ml-2" label x-small v-if="item.buyer && !item.onBehalfBuyer"
                        @click="addParty('onBehalfBuyer')"><v-icon small left>add</v-icon> {{ selectedOBOForwarder }} </v-chip>
                      <v-spacer></v-spacer>
                      <div v-if="!$vuetify.breakpoint.mobile">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" small @click="editParty('buyer', item.buyer)"><v-icon
                                small>edit</v-icon></v-btn>
                          </template>
                          <span>Edit Buyer</span>
                        </v-tooltip>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" color="red" small @click="removeParty('buyer')"><v-icon
                                small>delete</v-icon></v-btn>
                          </template>
                          <span>Remove Party</span>
                        </v-tooltip>

                        <v-tooltip top v-if="item.buyer && !item.buyerAddressId">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" color="orange" small @click="addAddress('buyer')"><v-icon
                                small>add_location</v-icon></v-btn>
                          </template>
                          <span>Add Address Details</span>
                        </v-tooltip>
                      </div>
                    </v-card-title>
                    <v-card-subtitle style="font-size: 14px" class="mb-0 pb-0" v-if="item.buyer && item.onBehalfBuyer">
                      On Behalf of
                      <a @click="
                        editParty('onBehalfBuyer', item.onBehalfBuyer)
                        ">{{ item.onBehalfBuyer.name }}</a></v-card-subtitle>
                    <v-card-text class="my-0 mt-0 pt-0" v-if="item.buyer">
                      <p style="font-size: 12px; color: grey" v-if="item.buyerAddressId ||
                        item.buyerAddressDescription
                        " class="text-wrap my-0 py-0">
                        <v-icon class="mr-1" small>location_on</v-icon>
                        <span v-if="item.buyerAddressDescription">{{
                          item.buyerAddressDescription
                        }}</span>
                        <span v-else>{{
                          concatenateAddress(item.buyerAddress)
                        }}</span>
                      </p>
                      <p v-if="item.onBehalfBuyer && item.onBehalfBuyerAddress" style="font-size: 12px; color: grey"
                        class="text-wrap my-0 py-0">
                        <v-icon class="mr-1" small>location_on</v-icon>
                        <span>{{
                          concatenateAddress(item.onBehalfBuyerAddress)
                        }}</span>
                      </p>
                    </v-card-text>
                  </v-col>
                </v-row>
                <v-card-actions v-if="$vuetify.breakpoint.mobile">
                  <v-row justify="center">
                    <v-col cols="6">
                      <v-btn text small @click="editParty('buyer', item.buyer)"><v-icon small left>edit</v-icon>
                        Edit</v-btn>
                    </v-col>
                    <v-col cols="6">
                      <v-btn text color="red" small @click="removeParty('buyer')"><v-icon small left>delete</v-icon>
                        Remove</v-btn>
                    </v-col>
                    <v-col cols="12">
                      <v-btn text v-if="item.buyer && !item.buyerAddressId" color="orange" small
                        @click="addAddress('buyer')"><v-icon small left>add_location</v-icon> Add
                        Address</v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
              <v-divider v-if="item.buyer" class="my-3" style="color: grey"></v-divider>


              <!-- First Notify -->
              <v-card flat v-if="item.firstNotify">
                <v-row align="center" class="mt-1">
                  <v-col cols="12" sm="2" class="text-center my-0 py-0">
                    <v-icon>notifications</v-icon> <br />
                    <span style="font-size: 10px; font-weight: bold">1st NOTIFY</span>
                  </v-col>
                  <v-col cols="12" sm="10" class="my-0 py-0">
                    <v-card-title :style="{
                      'font-size': '14px',
                      'padding-bottom': '0px'
                    }" class="my-0 pt-0">{{ item.firstNotify.name }}
                      <v-spacer></v-spacer>
                      <div v-if="!$vuetify.breakpoint.mobile">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" small
                              @click="editParty('firstNotify', item.firstNotify)"><v-icon small>edit</v-icon></v-btn>
                          </template>
                          <span>Edit First Notify Party</span>
                        </v-tooltip>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" color="red" small
                              @click="removeParty('firstNotify')"><v-icon small>delete</v-icon></v-btn>
                          </template>
                          <span>Remove Party</span>
                        </v-tooltip>

                        <v-tooltip top v-if="item.firstNotify && !item.firstNotifyAddressId">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" color="orange" small
                              @click="addAddress('firstNotify')"><v-icon small>add_location</v-icon></v-btn>
                          </template>
                          <span>Add Address Details</span>
                        </v-tooltip>
                      </div>
                    </v-card-title>

                    <v-card-text class="my-0 mt-0 pt-0">
                      <p style="font-size: 12px; color: grey" v-if="item.firstNotifyAddressId ||
                        item.firstNotifyAddressDescription
                        " class="text-wrap my-0 py-0">
                        <v-icon class="mr-1" small>location_on</v-icon>
                        <span v-if="item.firstNotifyAddressDescription">{{
                          item.firstNotifyAddressDescription
                        }}</span>
                        <span v-else>{{
                          concatenateAddress(item.firstNotifyAddress)
                        }}</span>
                      </p>
                    </v-card-text>
                  </v-col>
                </v-row>
                <v-card-actions v-if="$vuetify.breakpoint.mobile">
                  <v-row justify="center">
                    <v-col cols="6">
                      <v-btn text small @click="editParty('firstNotify', item.firstNotify)"><v-icon small
                          left>edit</v-icon> Edit</v-btn>
                    </v-col>
                    <v-col cols="6">
                      <v-btn text color="red" small @click="removeParty('firstNotify')"><v-icon small left>delete</v-icon>
                        Remove</v-btn>
                    </v-col>
                    <v-col cols="12">
                      <v-btn text v-if="item.firstNotify && !item.firstNotifyAddressId" color="orange" small
                        @click="addAddress('firstNotify')"><v-icon small left>add_location</v-icon> Add
                        Address</v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
              <v-divider v-if="item.firstNotify" class="my-3" style="color: grey"></v-divider>

              <!-- Second Notify -->
              <v-card flat v-if="item.secondNotify">
                <v-row align="center" class="mt-1">
                  <v-col cols="12" sm="2" class="text-center my-0 py-0">
                    <v-icon>notifications</v-icon> <br />
                    <span style="font-size: 10px; font-weight: bold">2nd NOTIFY</span>
                  </v-col>
                  <v-col cols="12" sm="10" class="my-0 py-0">
                    <v-card-title :style="{
                      'font-size': '14px',
                      'padding-bottom': '0px'
                    }" class="my-0 pt-0">{{ item.secondNotify.name }}
                      <v-spacer></v-spacer>
                      <div v-if="!$vuetify.breakpoint.mobile">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" small
                              @click="editParty('secondNotify', item.secondNotify)"><v-icon small>edit</v-icon></v-btn>
                          </template>
                          <span>Edit 2nd Notify Party</span>
                        </v-tooltip>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" color="red" small
                              @click="removeParty('secondNotify')"><v-icon small>delete</v-icon></v-btn>
                          </template>
                          <span>Remove Party</span>
                        </v-tooltip>

                        <v-tooltip top v-if="item.secondNotify && !item.secondNotifyAddressId">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" color="orange" small
                              @click="addAddress('secondNotify')"><v-icon small>add_location</v-icon></v-btn>
                          </template>
                          <span>Add Address Details</span>
                        </v-tooltip>
                      </div>
                    </v-card-title>

                    <v-card-text class="my-0 mt-0 pt-0">
                      <p style="font-size: 12px; color: grey" v-if="item.secondNotifyAddressId ||
                        item.secondNotifyAddressDescription
                        " class="text-wrap my-0 py-0">
                        <v-icon class="mr-1" small>location_on</v-icon>
                        <span v-if="item.secondNotifyAddressDescription">{{
                          item.secondNotifyAddressDescription
                        }}</span>
                        <span v-else>{{
                          concatenateAddress(item.secondNotifyAddress)
                        }}</span>
                      </p>
                    </v-card-text>
                  </v-col>
                </v-row>
                <v-card-actions v-if="$vuetify.breakpoint.mobile">
                  <v-row justify="center">
                    <v-col cols="6">
                      <v-btn text small @click="editParty('secondNotify', item.secondNotify)"><v-icon small
                          left>edit</v-icon> Edit</v-btn>
                    </v-col>
                    <v-col cols="6">
                      <v-btn text color="red" small @click="removeParty('secondNotify')"><v-icon small
                          left>delete</v-icon> Remove</v-btn>
                    </v-col>
                    <v-col cols="12">
                      <v-btn text v-if="item.secondNotify && !item.secondNotifyAddressId" color="orange" small
                        @click="addAddress('secondNotify')"><v-icon small left>add_location</v-icon> Add
                        Address</v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>

              <v-divider v-if="item.secondNotify" class="my-3" style="color: grey"></v-divider>

              <!-- Second Notify -->
              <v-card flat v-if="item.courierParty">
                <v-row align="center" class="mt-1">
                  <v-col cols="12" sm="2" class="text-center my-0 py-0">
                    <v-icon>local_post_office</v-icon> <br />
                    <span style="font-size: 10px; font-weight: bold">COURIER</span>
                  </v-col>
                  <v-col cols="12" sm="10" class="my-0 py-0">
                    <v-card-title :style="{
                      'font-size': '14px',
                      'padding-bottom': '0px'
                    }" class="my-0 pt-0">{{ item.courierParty.name }}
                      <v-spacer></v-spacer>
                      <div v-if="!$vuetify.breakpoint.mobile">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" small
                              @click="editParty('courierParty', item.courierParty)"><v-icon small>edit</v-icon></v-btn>
                          </template>
                          <span>Edit Courier Party</span>
                        </v-tooltip>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" color="red" small
                              @click="removeParty('courierParty')"><v-icon small>delete</v-icon></v-btn>
                          </template>
                          <span>Remove Party</span>
                        </v-tooltip>

                        <v-tooltip top v-if="item.courierParty && !item.courierPartyAddressId">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" color="orange" small
                              @click="addAddress('courierParty')"><v-icon small>add_location</v-icon></v-btn>
                          </template>
                          <span>Add Address Details</span>
                        </v-tooltip>
                      </div>
                    </v-card-title>

                    <v-card-text class="my-0 mt-0 pt-0">
                      <p style="font-size: 12px; color: grey" v-if="item.courierPartyAddressId ||
                        item.courierPartyAddressDescription
                        " class="text-wrap my-0 py-0">
                        <v-icon class="mr-1" small>location_on</v-icon>
                        <span v-if="item.courierPartyAddressDescription">{{
                          item.courierPartyAddressDescription
                        }}</span>
                        <span v-else>{{
                          concatenateAddress(item.courierPartyAddress)
                        }}</span>
                      </p>
                    </v-card-text>
                  </v-col>
                </v-row>
                <v-card-actions v-if="$vuetify.breakpoint.mobile">
                  <v-row justify="center">
                    <v-col cols="6">
                      <v-btn text small @click="editParty('courierParty', item.courierParty)"><v-icon small
                          left>edit</v-icon> Edit</v-btn>
                    </v-col>
                    <v-col cols="6">
                      <v-btn text color="red" small @click="removeParty('courierParty')"><v-icon small
                          left>delete</v-icon> Remove</v-btn>
                    </v-col>
                    <v-col cols="12">
                      <v-btn text v-if="item.courierParty && !item.courierPartyAddressId" color="orange" small
                        @click="addAddress('courierParty')"><v-icon small left>add_location</v-icon> Add
                        Address</v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-divider v-if="item.courierParty" class="my-3" style="color: grey"></v-divider>


    <!-- Address Dialog -->
    <v-dialog v-model="addressDialog" width="750px" persistent :fullscreen="$vuetify.breakpoint.mobile">
      <AddressModal :item="addressItem" :dialogVisible="addressDialog"
        @close="(addressDialog = false), (addressItem = {})" @save="saveNewAddress" transition="scale-transition" />
      <!-- <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Address Details</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="(addressDialog = false), (addressItem = {})"
            >X</v-btn
          >
        </v-toolbar>
        <v-card-text>
          <div>
            <v-text-field
              label="Address Line 1"
              @change="addressKey++"
              v-model="addressItem.addressLine1"
            ></v-text-field>
            <v-text-field
              label="Address Line 2"
              @change="addressKey++"
              v-model="addressItem.addressLine2"
            ></v-text-field>
            <v-text-field
              label="Address Line 3"
              @change="addressKey++"
              v-model="addressItem.addressLine3"
            ></v-text-field>
            <v-text-field
              label="Address Line 4"
              @change="addressKey++"
              v-model="addressItem.addressLine4"
            ></v-text-field>
            <v-text-field
              label="Postal Code"
              @change="addressKey++"
              v-model="addressItem.postalCode"
            ></v-text-field>
            <v-row justify="center">
              <v-btn
                text
                color="primary"
                :loading="savingAddress"
                @click="saveNewAddress"
                >Save</v-btn
              >
            </v-row>
          </div>
        </v-card-text>
      </v-card> -->
    </v-dialog>

    <!-- Party Modal -->
    <v-dialog v-model="dialog" persistent :width="'1200px'" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title style="text-transform: capitalize">
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn-toggle v-model="organisationSearchType" mandatory>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" value="contacts" id="#search-own-contacts">
                  <v-icon>perm_contact_calendar</v-icon>
                </v-btn>
              </template>
              <span>Search Contacts</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" value="global" id="#search-global-contacts">
                  <v-icon>search</v-icon>
                </v-btn>
              </template>
              <span>Search Global Directory</span>
            </v-tooltip>
          </v-btn-toggle>
          <v-btn v-if="organisationSearchType == 'contacts'" color="primary" text :loading="submittingPartyChange"
            @click="submitPartyChange()" :disabled="submitCheck" id="submit-party">Submit</v-btn>
          <v-btn class="ml-1" text @click="dialog = false" id="exit">X</v-btn>
        </v-toolbar>

        <v-card-text>
          <div v-if="organisationSearchType == 'contacts'">
            <v-row>
              <v-col cols="12" xs="12" sm="6">
                <v-autocomplete 
                  :key="relationshipKey" 
                  label="Search Linked Contacts" 
                  outlined
                  :items="filterOrganisations" 
                  clearable 
                  item-text="name" 
                  item-value="id" 
                  return-object
                  v-model="relationshipItem" 
                  id="search-contacts">
                  <template v-slot:item="data">
                    <template v-if="data.item.type && data.item.type == 'header'">
                      <v-list-item disabled>
                        <v-list-item-content v-text="data.item.title"></v-list-item-content>
                      </v-list-item>
                    </template>
                    <template v-else>
                      <v-list-item-avatar>
                        <img v-if="data.item.logo" :src="data.item.logo" />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>

              <!-- Options for different On Behalf options  -->
              <v-col cols="12" xs="12" sm="6">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" outlined>OBO Options</v-btn>
      </template>
      <v-list>
        <v-list-item v-for="option in OBOoptions" :key="option.value" @click="selectOption(option, 'general')">
          <v-list-item-title>{{ option.text }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-col>
            </v-row> 

            <v-row>
              <v-col cols="12" xs="12" sm="6" class="my-0 py-0" v-if="dialogType == 'new' &&
                this.relationshipItem &&
                this.relationshipItem.id
                ">
                <v-list dense id="parties">
                  <v-subheader>Parties</v-subheader>
                  <v-list-item v-for="role in availableRoles" :key="role.value">
                    <v-list-item-action>
                      <v-checkbox v-model="selectedRoles" :value="role.value"></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title style="text-transform: capitalize">
                        {{ role.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                <v-list v-if="relationshipItem && relationshipItem.id" id="listed-adresses">
                  <v-subheader>Listed Address
                    <!-- <v-btn icon color="primary"
                      @click="addAddress(dialogType)"><v-icon>add_circle_outline</v-icon></v-btn> -->
                  </v-subheader>
                  <v-list-item v-for="address in relationshipItem.organisationAddresses" :key="address.id">
                    <v-list-item-action :key="addressKey">
                      <v-btn icon @click="
                        changeSelectedAddress(dialogType, address, address.id)
                        ">
                        <v-icon :key="addressKey" v-if="(setAddress && setAddress.id == address.id) || item[dialogType + 'AddressId'] == address.id
                          " color="green">check</v-icon>
                        <span v-else>-</span>
                      </v-btn>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title class="text-wrap">{{
                        address.alias
                      }}</v-list-item-title>
                      <v-list-item-title class="text-wrap">{{
                        concatenateAddress(address)
                      }}</v-list-item-title>
                      <v-list-item-subtitle class="text-wrap">
                        <v-chip small class="mx-1" v-if="address.isHQ">Headquarters</v-chip>
                        <v-chip small class="mx-1" v-if="address.isDocsAddress">Documentation</v-chip>
                        <v-chip small class="mx-1" v-if="address.isDeliveryAddress">Delivery</v-chip>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <!-- <v-btn small icon @click="editAddress(address)"><v-icon small>edit</v-icon></v-btn> -->
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
                <div v-if="relationshipItem && courierSelected && dialogType == 'new'">
                  <v-text-field v-model="courierItem.pointOfContactName" outlined dense label="Point of Contact Name*">
                    <!-- <v-icon v-if="validatePointOfContact(item.pointOfContactName)" slot="append" class="mr-1" small color="green">check</v-icon>
                    <v-icon v-else slot="append" class="mr-1" small color="orange">warning</v-icon> -->
                  </v-text-field>
                  <v-text-field v-model="courierItem.pointOfContactPhone" outlined dense label="Point of Contact Number*">
                    <!-- <v-icon v-if="validatePhoneNumber(item.pointOfContactPhone)" slot="append" class="mr-1" small color="green">check</v-icon>
                    <v-icon v-else slot="append" class="mr-1" small color="orange">warning</v-icon> -->
                  </v-text-field>
                  <v-text-field v-model="courierItem.pointOfContactEmail" outlined dense label="Point of Contact Email*">
                    <!-- <v-icon v-if="validateEmail(item.pointOfContactEmail)" slot="append" class="mr-1" small color="green">check</v-icon>
                    <v-icon v-else slot="append" class="mr-1" small color="orange">warning</v-icon> -->
                  </v-text-field>
                </div>
              </v-col>
              <v-col>
                <div v-if="relationshipItem && courierSelected && dialogType != 'new'">
                  <v-text-field v-model="courierItem.pointOfContactName" outlined dense label="Point of Contact Name*">
                    <!-- <v-icon v-if="validatePointOfContact(item.pointOfContactName)" slot="append" class="mr-1" small color="green">check</v-icon>
                    <v-icon v-else slot="append" class="mr-1" small color="orange">warning</v-icon> -->
                  </v-text-field>
                  <v-text-field v-model="courierItem.pointOfContactPhone" outlined dense label="Point of Contact Number*">
                    <!-- <v-icon v-if="validatePhoneNumber(item.pointOfContactPhone)" slot="append" class="mr-1" small color="green">check</v-icon>
                    <v-icon v-else slot="append" class="mr-1" small color="orange">warning</v-icon> -->
                  </v-text-field>
                  <v-text-field v-model="courierItem.pointOfContactEmail" outlined dense label="Point of Contact Email*">
                    <!-- <v-icon v-if="validateEmail(item.pointOfContactEmail)" slot="append" class="mr-1" small color="green">check</v-icon>
                    <v-icon v-else slot="append" class="mr-1" small color="orange">warning</v-icon> -->
                  </v-text-field>
                </div>
              </v-col>
            </v-row>
          </div>
          <div v-else-if="organisationSearchType == 'global'">
            <OrganisationLookup :item="relationshipItem" :hideHeader="true" @refresh="submitNewRelationship" />
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import OrganisationLookup from "../../SystemComponents/OrganisationLookup.vue";
import AddressModal from "../../SystemComponents/AddressModal.vue";
export default {
  props: ["item", "related", "selectedCustomer"],
  components: {
    OrganisationLookup,
    AddressModal,
  },
  data: () => ({
    // For the different On Behalf options
    OBOoptions: [
      { text: 'ON BEHALF', value: 'On Behalf' },
      { text: 'AS AGENTS FOR', value: 'As Agents For' },
      { text: 'TRADING AS', value: 'Trading As' },
      { text: 'JOINTLY AND SEVERALLY WITH', value: 'Jointly and Severally With' },
    ],
    selectedOBOShipper: 'On Behalf', // Default value for Shipper
    selectedOBOBuyer: 'On Behalf', // Default value for Buyer
    selectedOBOConsignee: 'On Behalf', // Default value for Consignee
    selectedOBOForwarder: 'On Behalf', // Default value for Forwarder
    addressItem: {},
    addressDialog: false,
    addressKey: 2000,
    courierItem: {},
    dialog: false,
    dialogType: null,
    organisationSearchType: "contacts",
    relationshipItem: {},
    relationshipKey: 0,
    savingAddress: false,
    selectedRoles: [],
    setAddress: {},
    submittingPartyChange: false,
    updateKey: 0,
  }),
  watch: {
    "item.reference": {
      immediate: true,
      handler(val) {
        if (val)
          this.item.reference = this.item.reference.toUpperCase();
      },
    },
    "relationshipItem.id": {
      immediate: true,
      handler() {
        if (
          this.relationshipItem.organisationAddresses &&
          this.relationshipItem.organisationAddresses.length > 0 &&
          !this.item[this.dialogType + 'Address']
        ) {
          this.changeSelectedAddress(
            this.dialogType,
            this.relationshipItem.organisationAddresses[0],
            this.relationshipItem.organisationAddresses[0].id
          );
        }
        this.selectedRoles = this.currentRoles;
      },
    },
  },
  computed: {
    courierSelected() {
      return this.selectedRoles.includes('courierParty')
    },
    availableRoles() {
      let roles = [
        { name: "Shipper", value: "shipper" },
        { name: "Forwarder", value: "forwarder" },
        { name: "Consignee", value: "consignee" },
        { name: "Buyer", value: "buyer" },
        { name: "Notify", value: "firstNotify" },
        { name: "2nd Notify Party", value: "secondNotify" },
        { name: "Courier", value: "courierParty" },
      ];
      let filter = [];
      if (this.relationshipItem && this.relationshipItem.id) {
        filter = roles.filter(
          (x) =>
            !this.item[x.value + "Id"] ||
            this.item[x.value + "Id"] == this.relationshipItem.id
        );
      } else {
        filter = roles.filter((x) => !this.item[x.value + "Id"]);
      }
      return filter;
    },
    submitCheck() {
      let result = false
      // if(this.courierSelected){
      //   if (this.validateEmail(this.item.pointOfContactEmail) &&
      //       this.validatePhoneNumber(this.item.pointOfContactPhone) &&
      //       this.validatePointOfContact(this.item.pointOfContactName)) {
      //         result = false
      //   } else {
      //     result = true
      //   }
      // }
      // console.log(result)
      return result
    },
    filterOrganisations() {
      let result = this.related;
      if (this.dialogType) {
        result = result.filter((x) => x[this.dialogType]);
      }
      if (
        !result.find(
          (x) =>
            x.relatedOrganisationId ==
            this.selectedCustomer.relatedOrganisationId
        ) &&
        this.selectedCustomer[this.dialogType]
      ) {
        result.unshift(this.selectedCustomer);
      }
      let type = result.map((x) => x.relatedOrganisation);
      type.forEach((x) => {
        x.type = "related";
      });

      let all = this.related
        .map((x) => x.relatedOrganisation)
        .filter((x) => !type.map((y) => y.id).includes(x.id));
      let data = [];
      if (type.length > 0 && this.dialogType) {
        data = [
          {
            type: "header",
            title: `Filtered ${this.dialogType[0].toUpperCase() + this.dialogType.slice(1)
              } Contacts`,
          },
          ...type,
        ];
      }

      if (all.length > 0) {
        data = [...data, { type: "header", title: "All Contacts" }, ...all];
      }
      return data;
    },
    currentRoles() {
      let roles = [
        { name: "Shipper", value: "shipper" },
        { name: "Forwarder", value: "forwarder" },
        { name: "Consignee", value: "consignee" },
        { name: "Buyer", value: "buyer" },
        { name: "Notify", value: "firstNotify" },
        { name: "2nd Notify Party", value: "secondNotify" },
        { name: "Courier", value: "courierParty" },
      ];
      return roles
        .filter((x) => this.item[x.value + "Id"] == this.relationshipItem.id)
        .map((x) => x.value);
    },
  },
  methods: {
    //method for updating OBO 
    selectOption(option, type) {
      console.log('Selected option:', option);
      if (type === 'shipper') {
        this.selectedOBOShipper = option.value;
      } else if (type === 'buyer') {
        this.selectedOBOBuyer = option.value;
      } else if (type === 'consignee') {
        this.selectedOBOConsignee = option.value;
      } else if (type === 'forwarder') {
        this.selectedOBOForwarder = option.value;
      }
    },


    addAddress(type) {
      this.addressItem = {
        organisationId: type && this.item[type] ? this.item[type + "Id"] : this.relationshipItem.id,
        addressLine1: null,
        addressLine2: null,
        addressLine3: null,
        addressLine4: null,
        postalCode: null,
        country: type && this.item[type] ? this.item[type].country : this.relationshipItem.country,
        countryCode: type && this.item[type] ? this.item[type].countryCode : this.relationshipItem.countryCode
      };
      this.addressItem.type = type;
      this.addressItem.new = true;
      this.addressDialog = true;
    },
    addParty(type) {
      // if (type == 'new') { this.courierSelected = false }
      this.courierItem = {}
      this.dialogType = type;
      this.organisationSearchType = "contacts";
      this.relationshipItem = {
        modalType: "Add",
        relatedOrganisation: {
          organisationUsers: [],
        },
        organisationTags: [],
        organisationId: this.selectedCustomer.relatedOrganisationId,
        producer: false,
        coldstore: false,
        consignee: false,
        buyer: false,
        forwarder: false,
        notifyParty: false,
        shipper: false,
        jointShipper: false,
        jointForwarder: false,
        jointBuyer: false,
        inverse: {
          producer: this.selectedCustomer.producer,
          coldstore: this.selectedCustomer.coldstore,
          consignee: this.selectedCustomer.consignee,
          buyer: this.selectedCustomer.buyer,
          forwarder: this.selectedCustomer.forwarder,
          notifyParty: this.selectedCustomer.notifyParty,
          shipper: this.selectedCustomer.shipper,
        },
        isCustomer: false,
        isSupplier: false,
        relationshipTeams: [],
      };
      this.selectedRoles = [];
      this.relationshipItem[type] = true;
      if (type == "firstNotify" || type == "secondNotify") {
        this.relationshipItem.notifyParty = true;
      }
      this.dialog = true;
    },
    changeSelectedAddress(type, address, addressId) {
      if (type != "new") {
        this.item[`${type}Address`] = address;
        this.item[`${type}AddressId`] = addressId;
      }

      this.setAddress = address;
      this.addressKey++;
    },
    editAddress(item) {
      this.addressItem = item;
      this.addressDialog = true;
    },
    editParty(type, organisation) {
      // if(type == 'courierParty') { this.courierSelected = true }
      this.courierItem = {
        pointOfContactEmail: this.item.pointOfContactEmail,
        pointOfContactName: this.item.pointOfContactName,
        pointOfContactPhone: this.item.pointOfContactPhone,
      }
      this.dialogType = type;
      this.organisationSearchType = "contacts";
      this.relationshipItem = organisation;
      // this.setAddress = {}

      this.setAddress = this.item[`${type}Address`]
      console.log(this.setAddress)
      console.log('Address ID', this.item[`${type}AddressId`])
      this.addressKey++
      this.dialog = true;
    },
    formatDialogTitle(name) {
      switch (name) {
        case "courierParty":
          return "Courier Party";
        case "firstNotify":
          return "First Notify Party";
        case "secondNotify":
          return "Second Notify Party";
        case "onBehalfShipper":
          return "Shipping on behalf of";
        case "onBehalfBuyer":
          return "On Behalf of Buyer";
        case "onBehalfForwarder":
          return "On Behalf of Forwarder";
        case "onBehalfConsignee":
          return "On behalf of Consignee";
        default:
          return name;
      }
    },
    constructAddressLine(addressLine, address) {
      if (address) {
        switch (addressLine) {
          case 1:
            return [address.addressLine1, address.addressLine2].filter(Boolean).join(', ')
          case 2:
            return [address.addressLine3, address.addressLine4].filter(Boolean).join(', ')
          case 3:
            return [address.town, address.subdivision, address.country, address.postalCode].filter(Boolean).join(', ')
        }
      }
    },
    concatenateAddress(address) {

      let result = [
        this.constructAddressLine(1, address),
        this.constructAddressLine(2, address),
        this.constructAddressLine(3, address)
      ];
      // if (address.addressLine1) {
      //   result.push(address.addressLine1);
      // }
      // if (address.addressLine2) {
      //   result.push(address.addressLine2);
      // }
      // if (address.addressLine4) {
      //   result.push(address.addressLine3);
      // }
      // if (address.addressLine4) {
      //   result.push(address.addressLine4);
      // }
      // if (address.town) {
      //   result.push(address.town);
      // }
      // if (address.subdivision) {
      //   result.push(address.subdivision);
      // }
      // if (address.country) {
      //   result.push(address.country);
      // }
      // if (address.postalCode) {
      //   result.push(address.postalCode);
      // }
      return result.filter(Boolean).join(", ");
    },
    async removeParty(type) {
      this.item[type + "Id"] = null;
      this.item[type] = null;
      this.item[type + "AddressId"] = null;
      this.item[type + "Address"] = null;
      this.updateKey++;
    },
    async saveNewAddress() {
      this.savingAddress = true;
      if (this.relationshipItem && this.relationshipItem.id) {
        let findIndex = this.relationshipItem.organisationAddresses.findIndex(
          (x) => x.id == this.addressItem.id
        );
        if (findIndex > -1) {
          this.relationshipItem.organisationAddresses[findIndex] =
            this.addressItem;
        } else {
          this.relationshipItem.organisationAddresses.push(this.addressItem);
        }
      } else {
        this.item[this.addressItem.type + "AddressId"] = this.addressItem.id;
        this.item[this.addressItem.type + "Address"] = this.addressItem;
      }

      if (this.item.id) {
        await this.$API.updateConsigneeProfile(this.item);
      }

      this.addressDialog = false;
      this.addressItem = {};
      this.savingAddress = false;
    },
    async submitNewRelationship(item) {
      item.relatedOrganisationId = item.relatedOrganisation.id;
      item.organisationId = this.selectedCustomer.relatedOrganisationId;
      item.inviteAccepted = true;
      (item.inverse = {
        producer: this.selectedCustomer.producer,
        coldstore: this.selectedCustomer.coldstore,
        consignee: this.selectedCustomer.consignee,
        buyer: this.selectedCustomer.buyer,
        forwarder: this.selectedCustomer.forwarder,
        notifyParty: this.selectedCustomer.notifyParty,
        shipper: this.selectedCustomer.shipper,
      }),
        (this.item[this.dialogType] = item.relatedOrganisation);
      this.item[this.dialogType + "Id"] = item.relatedOrganisation.id;
      if (
        item.relatedOrganisation.organisationAddresses &&
        item.relatedOrganisation.organisationAddresses.length > 0
      ) {
        this.changeSelectedAddress(
          this.dialogType,
          item.relatedOrganisation.organisationAddresses[0],
          item.relatedOrganisation.organisationAddresses[0].id
        );
      }
      if (this.item.id) {
        await this.$API.updateConsigneeProfile(this.item);
      }
      this.organisationSearchType = "contacts";

      let find = this.related.find((x) => x.id == item.id);
      if (!find) {
        let relation = await this.$API.createRelation(item);
        this.related.push(relation);
        this.relationshipItem = relation.relatedOrganisation;
      }
      this.$emit("refreshRelated");
      this.$emit('refresh')
      this.updateKey++;
    },
    async submitPartyChange() {
      this.submittingPartyChange = true;
      this.item.pointOfContactEmail = this.courierItem.pointOfContactEmail;
      this.item.pointOfContactName = this.courierItem.pointOfContactName;
      this.item.pointOfContactPhone = this.courierItem.pointOfContactPhone;

      if (this.relationshipItem && this.relationshipItem.id) {
        if (this.dialogType == "new") {
          let removeList = this.currentRoles.filter(
            (x) => !this.selectedRoles.includes(x)
          );
          for (let i = 0; i < removeList.length; i++) {
            this.item[removeList[i]] = null;
            this.item[removeList[i] + "Id"] = null;
            this.item[removeList[i]] = null;
            this.item[removeList[i] + "AddressId"] = null;
            this.item[removeList[i] + "AddressDescription"] = null;
          }
          for (let i = 0; i < this.selectedRoles.length; i++) {
            this.item[this.selectedRoles[i]] = this.relationshipItem;
            this.item[this.selectedRoles[i] + "Id"] = this.relationshipItem.id;
            this.item[this.selectedRoles[i] + "AddressId"] = this.setAddress.id;
            this.item[this.selectedRoles[i] + "Address"] = this.setAddress;
          }
        } else {
          this.item[this.dialogType] = this.relationshipItem;
          this.item[this.dialogType + "Id"] = this.relationshipItem.id;
        }
      } else {
        this.item[this.dialogType] = null;
        this.item[this.dialogType + "Id"] = null;
        this.item[this.dialogType + "AddressId"] = null;
        this.item[this.dialogType + "AddressDescription"] = null;
      }
      if (this.item.id) {
        await this.$API.updateConsigneeProfile(this.item);
      }
      this.dialog = false;
      // this.courierSelected = false;
      this.dialogType = null;
      this.updateKey++;
      this.submittingPartyChange = false;
    },
    async submitNewOrganisation() {
      let org = await this.$API.createOrganisation(this.relationshipItem);
      this.relationshipItem.relatedOrganisationId = org.id;
      this.relationshipItem.inviteAccepted = true;
      this.relationshipItem.organisationId =
        this.selectedCustomer.relatedOrganisationId;

      let relation = await this.$API.createRelation(this.relationshipItem);
      this.related.push(relation);
      this.item[this.dialogType] = org;
      this.item[this.dialogType + "Id"] = org.id;
      if (this.item.id) {
        await this.$API.updateConsigneeProfile(this.item);
      }
      this.dialog = false;
      this.dialogType = null;
      this.updateKey++;
    },
    // roleSelected(role) {
    //   if(role == 'courierParty' && this.selectedRoles.includes('courierParty')){
    //     this.courierSelected = true
    //   } else if (role == 'courierParty' && !this.selectedRoles.includes('courierParty')){
    //     this.courierSelected = false
    //   }
    // },
    validateEmail(email) {
      let result = false
      if (email) {
        result = email.match(
          //eslint-disable-next-line
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
      }
      return result
    },
    validatePhoneNumber(phoneNumber) {
      let result = false
      if (phoneNumber) {
        result = phoneNumber && phoneNumber.length > 0
        // .match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)
      }
      return result
    },
    validatePointOfContact(contactText) {
      let result = false
      if (contactText) {
        if (contactText.trim() != '') { result = true }
      }
      return result
    },
  },
};
</script>
<style scoped>
.dense-list-item {
  height: 60px;
}

.infoButton {
  /* position: relative; */
  right: 280px
}
</style>
